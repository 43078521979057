import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import {
  createUser,
  deleteUser,
  disableUser,
  doesHubUserExist,
  enableUser,
  fetchUserAttributes,
  getAccountsForLevel,
  getAllUsers,
  getUserById,
  registerUser,
  resendWelcomeEmail,
  updateCognitoUser,
  updateMyUser,
  updateUser,
} from "./api";
import {
  CognitoUser,
  GetUsersResponse,
  RegisterUserParams,
  User,
  UserCreateUpdateRequest,
  UserDetails,
  UserId,
  UserListItem,
} from "./types";
import { AttributeType } from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";
import { GetUserCommandOutput, UpdateUserAttributesCommandOutput } from "@aws-sdk/client-cognito-identity-provider";
import { mapToCognitoUser } from "./utils";
import { useFeedbackAlerts, useStore } from "../_app/hooks";
import { getLastParent } from "../context/utils";
import { updateIdentityUser } from "../auth/api";
import { IdentityUser } from "../auth/types";

export function useUserRegister(options = {}) {
  return useMutation<any, AjaxResponse, RegisterUserParams>(
    (user: RegisterUserParams) => registerUser(user.accountCode, user.email, user.reCaptchaToken),
    options,
  );
}

export function useHubUserExist(options = {}) {
  return useQuery<boolean, AjaxResponse>(["hubUserExist"], doesHubUserExist, options);
}

export function useAllUsers(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetUsersResponse, AjaxResponse>(
    ["users", { page, limit }],
    async () => {
      const users = await getAllUsers(page, limit);
      return users;
    },
    {
      keepPreviousData: true,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message || "Failed to fetch users");
      },
      ...options,
    },
  );
}

export function useSingleUser(id: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<User, AjaxResponse>(["user", id], () => getUserById(id), {
    ...options,
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useUserCreate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<UserId, AjaxResponse, UserCreateUpdateRequest>((user: UserCreateUpdateRequest) => createUser(user), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      options?.onSuccess?.();
      setFeedbackAlertSuccess("User created");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useUserUpdate(userId: string, options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<User, AjaxResponse, UserCreateUpdateRequest>((user: UserCreateUpdateRequest) => updateUser(user, userId), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["user", userId]);
      queryClient.invalidateQueries(["users"]);
      options?.onSuccess?.();
      setFeedbackAlertSuccess("User updated");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useCognitoUserAttributes(options = {}) {
  return useQuery<GetUserCommandOutput, Error, CognitoUser>(["userAttributes"], () => fetchUserAttributes(), {
    select: (data) => mapToCognitoUser(data.UserAttributes),
    ...options,
  });
}

export function useCognitoUserUpdate(options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const queryClient = useQueryClient();

  return useMutation<UpdateUserAttributesCommandOutput, Error, any>(
    (attributes: AttributeType[]) => updateCognitoUser(attributes),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(["userAttributes"]);
        setFeedbackAlertSuccess("User updated");
      },
      onError: () => {
        setFeedbackAlertError("Could not update user");
      },
    },
  );
}

export function useNonCognitoUserUpdate(options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const queryClient = useQueryClient();

  const myUserUpdate = useMutation(updateMyUser);
  const identityUserUpdate = useMutation(updateIdentityUser);

  const rollbackMyUserUpdate = async (identityUser: IdentityUser) => {
    const originalUser: UserDetails = {
      firstName: identityUser?.profile?.given_name ?? "",
      lastName: identityUser?.profile?.family_name ?? "",
      email: identityUser?.profile?.email ?? "",
    } as UserDetails;

    try {
      await myUserUpdate.mutateAsync(originalUser);
    } catch (error) {
      console.error("Rollback failed", error);
    }
  };

  const updateUser = async (identityUser: IdentityUser, userDetails: UserDetails) => {
    let myUserUpdateResponse;
    try {
      await myUserUpdate.mutateAsync(userDetails);
      await identityUserUpdate.mutateAsync(
        {
          ...identityUser,
          profile: {
            ...identityUser?.profile,
            given_name: userDetails.firstName,
            family_name: userDetails.lastName,
            email: userDetails.email,
          },
        } as IdentityUser,
        options,
      );
      queryClient.invalidateQueries(["user", identityUser?.profile?.sub ?? ""]);
      queryClient.invalidateQueries(["users"]);
      queryClient.invalidateQueries(["identityUser"]);
      setFeedbackAlertSuccess("User updated");

      return userDetails;
    } catch (error) {
      if (myUserUpdateResponse) {
        await rollbackMyUserUpdate(identityUser);
      }
      setFeedbackAlertError("Could not update user");
    }
  };

  return {
    updateUser,
    isLoading: identityUserUpdate.isLoading || myUserUpdate.isLoading,
  };
}

export function useUserDelete(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<UserListItem, AjaxResponse, UserListItem>((user: UserListItem) => deleteUser(user), {
    ...options,
    onSuccess: (data: UserListItem) => {
      queryClient.invalidateQueries(["user", data?.id]);
      queryClient.invalidateQueries(["users"]);
      setFeedbackAlertSuccess("User deleted");
    },
    onError: (error: AjaxResponse) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useUserDisable(options = {}) {
  const queryClient = useQueryClient();
  return useMutation<UserListItem, AjaxResponse, UserListItem>((user: UserListItem) => disableUser(user), {
    onSuccess: (data: UserListItem) => {
      queryClient.invalidateQueries(["user", data?.id]);
      queryClient.invalidateQueries(["users"]);
    },
    ...options,
  });
}

export function useUserEnable(options = {}) {
  const queryClient = useQueryClient();
  return useMutation<UserListItem, AjaxResponse, UserListItem>((user: UserListItem) => enableUser(user), {
    onSuccess: (data: UserListItem) => {
      queryClient.invalidateQueries(["user", data?.id]);
      queryClient.invalidateQueries(["users"]);
    },
    ...options,
  });
}

export function useUserResendEmail(options = {}) {
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<UserListItem, AjaxResponse, UserListItem>((user: UserListItem) => resendWelcomeEmail(user), {
    ...options,
    onSuccess: () => {
      setFeedbackAlertSuccess("Welcome email sent");
    },
  });
}

export function useAccountsForLevel(level: string, params: any, options = {}) {
  return useInfiniteQuery<any, AjaxResponse>(
    ["accountsForLevel", level, JSON?.stringify(params || {})],
    ({ pageParam = 0 }) => {
      if (level !== undefined || params?.parent !== "0") {
        return getAccountsForLevel(level, {
          offset: pageParam,
          ...params,
        });
      } else {
        return [];
      }
    },
    {
      getNextPageParam: (lastPage: any) => {
        const limit = lastPage?.pageSize * lastPage?.page + 1;
        return lastPage?.page < limit / lastPage?.pageSize && Boolean(lastPage?.list?.length)
          ? lastPage.pageSize + lastPage.page * limit
          : undefined;
      },
      ...options,
    },
  );
}

export const useUserData = () => {
  const { state } = useStore();
  const userAccounts = getLastParent(state.contextHierarchy)?.userAccessibleAccounts ?? [];
  const userLevel = userAccounts?.[0]?.level?.id ?? 0;
  return { userAccounts, userLevel };
};
