import api from "../_app/api";
import { parsePermissions } from "../permission/api";
import { GetUsersResponse, User, UserCreateUpdateRequest, UserDetails, UserId, UserListItem } from "./types";
import {
  GetUserCommand,
  GetUserCommandOutput,
  UpdateUserAttributesCommand,
  UpdateUserAttributesCommandOutput,
} from "@aws-sdk/client-cognito-identity-provider";
import { cognitoIdentityProviderClient } from "../_app/lib/cognito";
import { AttributeType } from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";
import { getIdentityUser } from "../auth/api";

export const registerUser = async (accountCode: string, email: string, reCaptchaToken: string) => {
  return await api({
    method: "PUT",
    url: "/users/register",
    data: {
      accountCode,
      email,
      reCaptchaToken,
    },
    skip_bearer_injection: true,
    skip_context_path_injection: true,
    skip_token_refresh: true,
  });
};

export const getAllUsers = async (page = 0, size = 25) => {
  const { data }: { data: GetUsersResponse } = await api({
    method: "GET",
    url: "/users",
    context_injection: true,
    params: { page, size },
  });
  return data;
};

export const getUserById = async (id: string) => {
  const { data }: { data: User } = await api({
    method: "GET",
    url: "/users/" + id,
  });
  return parseUser(data);
};

export const createUser = async (userCreateUpdateRequest: UserCreateUpdateRequest) => {
  const { data }: { data: UserId } = await api({
    method: "POST",
    url: "/users",
    data: userCreateUpdateRequest,
  });

  return data;
};

export const updateUser = async (userCreateUpdateRequest: UserCreateUpdateRequest, userId: string) => {
  const { data } = await api({
    method: "PUT",
    url: `/users/${userId}`,
    data: userCreateUpdateRequest,
  });

  return data;
};

export const doesHubUserExist = async () => {
  try {
    const response = await api({
      method: "GET",
      url: "/users/my/exists",
    });

    return response.status === 204;
  } catch (error) {
    return false;
  }
};

export const updateMyUser = async (user: UserDetails) => {
  await api({
    method: "PUT",
    url: `/users/my`,
    data: {
      ...user,
    },
  });
};

export const deleteUser = async (user: UserListItem) => {
  const { data }: { data: UserListItem } = await api({
    method: "DELETE",
    url: "/users/" + user?.id,
  });
  return data;
};

export const disableUser = async (user: UserListItem) => {
  const { data }: { data: UserListItem } = await api({
    method: "PUT",
    url: "/users/" + user?.id + "/disable",
  });
  return data;
};

export const enableUser = async (user: UserListItem) => {
  const { data }: { data: UserListItem } = await api({
    method: "PUT",
    url: "/users/" + user?.id + "/enable",
  });
  return data;
};

export const resendWelcomeEmail = async (user: UserListItem) => {
  const { data }: { data: UserListItem } = await api({
    method: "POST",
    url: "/users/" + user?.id + "/email",
  });
  return data;
};

export const parseUser = (user: User) => {
  user.permissions = user?.permissions?.map(parsePermissions);
  return user;
};

export const getAccountsForLevel = async (level: string, params: any) => {
  const requestParams: any = {};
  if (params?.parent) {
    requestParams.parent = params?.parent;
  }
  if (params?.subLevel) {
    // params.subLevel = subLevel;
    return;
  }
  if (params["search-term"]) {
    requestParams["search-term"] = params["search-term"];
  }
  if (params?.limit) {
    requestParams.limit = params?.limit;
  }
  if (params?.offset) {
    requestParams.offset = params?.offset;
  }

  const { data }: { data: any } = await api({
    method: "GET",
    url: "/accounts/" + level,
    params: requestParams,
    context_injection: false,
  });
  return data;
};

export const updateCognitoUser = async (attributes: AttributeType[]): Promise<UpdateUserAttributesCommandOutput> => {
  const user = await getIdentityUser();

  const command = new UpdateUserAttributesCommand({
    AccessToken: user?.access_token,
    UserAttributes: attributes,
  });

  return cognitoIdentityProviderClient.send(command);
};

export const fetchUserAttributes = async (): Promise<GetUserCommandOutput> => {
  const user = await getIdentityUser();

  const command = new GetUserCommand({
    AccessToken: user?.access_token,
  });

  return cognitoIdentityProviderClient.send(command);
};
